import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'role_select',
    'customer_select',
    'partner_select',
    'vendor_select',
    'user_active_regions',
  ];

  connect() {
    this.userRoleSelected();
  }

  userRoleSelected() {
    /* Hide company select field and set required to false */
    this.customer_selectTarget.classList.add('hidden');
    this.partner_selectTarget.classList.add('hidden');
    this.vendor_selectTarget.classList.add('hidden');
    this.user_active_regionsTarget.classList.add('hidden');
    this.customer_selectTarget.children[1].required = false;
    this.partner_selectTarget.children[1].required = false;
    this.vendor_selectTarget.children[1].required = false;
    /* Show company select field if role is customer/partner/vendor */
    /* Show active regions select field if role is admin_by_region or tsa */
    switch (this.role_selectTarget.value) {
      case 'admin':
        break;
      case 'admin_by_region':
        this.user_active_regionsTarget.classList.remove('hidden');
        break;
      case 'tsa':
        this.user_active_regionsTarget.classList.remove('hidden');
        break;
      case 'customer':
        this.customer_selectTarget.classList.remove('hidden');
        this.customer_selectTarget.children[1].required = true;
        break;
      case 'partner':
        this.partner_selectTarget.classList.remove('hidden');
        this.partner_selectTarget.children[1].required = true;
        break;
      case 'vendor':
        this.vendor_selectTarget.classList.remove('hidden');
        this.vendor_selectTarget.children[1].required = true;
        break;
    }
  }
}
