import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'select',
    'choice',
    'long',
    'required',
    'updated_by_tsa',
    'section_type_select',
    'add_solution',
    'add_question',
    'show_columns',
  ];

  connect() {
    this.sectionTypeSelected();
    this.questionTypeselected();
  }

  questionTypeselected() {
    if (this.hasChoiceTarget || this.hasLongTarget || this.hasRequiredTarget) {
      this.choiceTarget.classList.add('hidden');
      this.longTarget.classList.add('hidden');
      this.requiredTarget.classList.add('hidden');
      this.updated_by_tsaTarget.classList.add('hidden');

      switch (this.selectTarget.value) {
        case 'single_choice':
        case 'dropdown_select':
          this.choiceTarget.classList.remove('hidden');
          this.requiredTarget.classList.remove('hidden');
          break;
        case 'multiple_choice':
          this.choiceTarget.classList.remove('hidden');
          this.updated_by_tsaTarget.classList.remove('hidden');
          break;
        case 'long_answer':
          this.longTarget.classList.remove('hidden');
          this.requiredTarget.classList.remove('hidden');
          break;
      }
    }
  }

  sectionTypeSelected() {
    if (this.hasAdd_questionTarget || this.hasAdd_solutionTarget) {
      this.add_questionTarget.classList.add('hidden');
      this.add_solutionTarget.classList.add('hidden');
      this.show_columnsTarget.classList.add('hidden');

      switch (this.section_type_selectTarget.value) {
        case 'default':
          this.add_questionTarget.classList.remove('hidden');
          break;
        case 'solution':
          this.add_solutionTarget.classList.remove('hidden');
          this.show_columnsTarget.classList.remove('hidden');
          break;
      }
    }
  }
}
