import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['add_item', 'template'];
  static values = { index: String };

  add_association(event) {
    event.preventDefault();
    var content = this.templateTarget.innerHTML.replace(
      new RegExp(this.indexValue, 'g'),
      new Date().getTime()
    );
    this.add_itemTarget.insertAdjacentHTML('beforebegin', content);
  }

  remove_association(event) {
    event.preventDefault();
    let confirmed = confirm('Are you sure?');
    if (confirmed) {
      let item = event.target.closest('.nested-fields');
      item.querySelector("input[name*='_destroy']").value = 1;
      item.style.display = 'none';
    }
  }

  archive_association(event) {
    event.preventDefault();
    let confirmed = confirm('Are you sure?');
    if (confirmed) {
      let item = event.target.closest('.nested-fields');
      if (item.querySelector("input[name*='is_archived']")) {
        item.querySelector("input[name*='is_archived']").value = true;
      } else if (item.querySelector("input[name*='_destroy']")) {
        item.querySelector("input[name*='_destroy']").value = 1;
      }

      item.style.display = 'none';
    }
  }
}
