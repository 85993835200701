// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';
import 'bootstrap';
import $ from 'jquery';

import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

import Swal from 'sweetalert2';
import AOS from 'aos';

import '../utility/select2Setup';

// const fonts = require.context('../fonts', true)
// const images = require.context('../images', true)

Rails.start();
Turbolinks.start();
ActiveStorage.start();
const application = Application.start();
const context = require.context('../controllers', true, /\.js$/);
application.load(definitionsFromContext(context));

global.$ = $;
global.jQuery = $;

window.Swal = Swal.mixin({
  position: 'top-end',
  background: '#032B7E',
  color: 'white',
  backdrop: false,
  icon: 'success',
});

document.addEventListener('DOMContentLoaded', function () {
  AOS.init({
    startEvent: 'turbolinks:load', // if you are using turbolinks
    offset: 120,
    delay: 150,
    duration: 450, // or 200, 250, 300, 350.....
    easing: 'ease-in-out-quad',
    once: true,
    disable: 'mobile',
  });
});
