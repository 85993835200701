import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.css';
import 'select2-bootstrap-theme/dist/select2-bootstrap.css';
import '../stylesheets/select2-custom.scss';

$(document).on('turbolinks:load', function () {
  $('.select2-multiple').select2({
    dropdownParent: $('.select2-multiple').closest('.form-group'),
    tags: true,
    tokenSeparators: [',', ' '],
  });
});
